<template>
    <div>
        <div class="my-click">
            <el-row :gutter="20">
                <el-col :span="18">
                    <h3>Hujjatlar</h3>
                </el-col>
            </el-row>

            <div class="fayil-full">
                <div class="fayil-itme" v-for="(file, index) in files" :key="index">
                    <div class="itme-disct-doc">
                        <div class="itme-disct-left">
                            <a href="#" @click="downloadFile(file)">
                                <i class="el-icon-download"></i>
                            </a>
                            <a :href="appURL + file.path" target="_blank">
                                <i class="el-icon-view"></i>
                            </a>
                            <!-- <i><b>25MG</b></i> -->
                        </div>
                        <div class="itme-disct-right">
                            <img src="./../../../public/img/file.png" />
                        </div>
                    </div>
                    <div class="name-distc">Fayil: <b>{{ file.original_name ? file.original_name : ''}}</b></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
    name: 'dacument',
    created(){
        this.files = this.$route.params.files;
    },
    data(){
        return {
            appURL: process.env.VUE_APP_URL_DOCS
        }
    },
    methods: {
        ...mapActions({
            download_file: 'doctors/downloadFile'
        }),
        downloadFile(file){      
            let fileName = file.original_name;
            let query = {file_id: file.id};
            this.download_file(query).then((res) => {
                const file_url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/*'}));
                const file_link = document.createElement('a');
                file_link.href = file_url;
                file_link.setAttribute('download', fileName);
                document.body.appendChild(file_link);
                file_link.click();
            }).catch((err) => {
                console.log(err.response.data);
            });
            // let split_el = _.split(res.data.type, '/', 2);
            // let hasfileName = (fileName) ? fileName : (split_el[0] + '.' + split_el[1]); 
        },
    }
}
</script>
<style>
.el-icon-view{
    cursor: pointer;
}
</style>

